import React from 'react'

interface PropsFromComponent {
    children?: React.ReactNode
}

type Props = PropsFromComponent;

export default function HackCard({ children }: Props) {
    return (
        <div className="relative w-full cursor-pointer text-white">
            <div className="p-4 bg-gray-900 rounded-2xl flex flex-col">
                <div className="bg-gradient-to-b from-gray-700 to-gray-800 rounded-2xl mb-4 py-10 flex items-center justify-center">
                    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40.9998 28.9297C34.3438 28.9297 28.9292 34.3442 28.9292 41.0003V45.8349H31.3497C32.6761 45.8349 33.7526 46.9098 33.7526 48.2378V53.0709H38.5969V49.4425C38.5969 48.1161 39.6718 47.0396 40.9998 47.0396C42.3262 47.0396 43.4027 48.1161 43.4027 49.4425V53.0707H48.247V48.2377C48.247 46.9097 49.3219 45.8348 50.6499 45.8348H53.0704V41.0001C53.0704 34.3442 47.6543 28.9297 40.9998 28.9297ZM36.1748 40.9907C34.8484 40.9907 33.7719 39.914 33.7719 38.586C33.7719 37.2598 34.8484 36.1831 36.1748 36.1831C37.5028 36.1831 38.5793 37.2598 38.5793 38.586C38.5793 39.9142 37.5028 40.9907 36.1748 40.9907ZM45.8232 40.9907C44.4968 40.9907 43.4187 39.914 43.4187 38.586C43.4187 37.2598 44.4968 36.1831 45.8232 36.1831C47.1496 36.1831 48.2261 37.2598 48.2261 38.586C48.2261 39.9142 47.1496 40.9907 45.8232 40.9907Z" fill="white" />
                        <path d="M79.5971 31.3595H68.2938C67.9526 30.3936 67.5585 29.4436 67.1148 28.5177L76.9363 18.6978C77.8734 17.7591 77.8734 16.2372 76.9363 15.2985L66.7015 5.06373C65.7628 4.12659 64.2409 4.12659 63.3022 5.06373L53.4823 14.8852C52.5564 14.4415 51.6064 14.0474 50.6405 13.7062V2.40291C50.6405 1.0765 49.564 0 48.2376 0H33.7624C32.436 0 31.3595 1.0765 31.3595 2.40291V13.7062C30.3936 14.0474 29.4436 14.4415 28.5177 14.8852L18.6978 5.06373C17.7591 4.12659 16.2372 4.12659 15.2985 5.06373L5.06373 15.2985C4.12659 16.2372 4.12659 17.7591 5.06373 18.6978L14.8852 28.5177C14.4415 29.4436 14.0474 30.3936 13.7062 31.3595H2.40291C1.0765 31.3595 0 32.436 0 33.7624V48.2374C0 49.5638 1.0765 50.6403 2.40291 50.6403H13.7062C14.0474 51.6063 14.4415 52.5562 14.8852 53.4821L5.06373 63.302C4.12659 64.2408 4.12659 65.7626 5.06373 66.7015L15.2985 76.9363C16.2372 77.8734 17.7591 77.8734 18.6978 76.9363L28.5177 67.1146C29.4436 67.5585 30.3936 67.9525 31.3595 68.2937V79.5971C31.3595 80.9233 32.436 82 33.7624 82H48.2376C49.564 82 50.6405 80.9233 50.6405 79.5971V68.2938C51.6064 67.9526 52.5564 67.5585 53.4823 67.1148L63.3022 76.9364C64.2409 77.8736 65.7628 77.8736 66.7015 76.9364L76.9363 66.7017C77.8734 65.7629 77.8734 64.2411 76.9363 63.3022L67.1148 53.4823C67.5585 52.5564 67.9526 51.6064 68.2938 50.6405H79.5971C80.9235 50.6405 82 49.564 82 48.2376V33.7624C82 32.436 80.9235 31.3595 79.5971 31.3595ZM57.8764 48.2376C57.8764 49.564 56.7999 50.6405 55.4735 50.6405H53.053V55.4735C53.053 56.7999 51.9765 57.8764 50.6501 57.8764H31.3499C30.0219 57.8764 28.947 56.7999 28.947 55.4735V50.6405H26.5265C25.1985 50.6405 24.1236 49.564 24.1236 48.2376V41C24.1236 31.6943 31.6943 24.1236 41 24.1236C50.3057 24.1236 57.8764 31.6943 57.8764 41V48.2376Z" fill="white" />
                    </svg>

                </div>
                <div className="text-center text-gray-500">Hack</div>
                <div className="relative text-center"><span className="absolute line-through left-0 text-gray-500">500</span>250р.</div>
                <div className="absolute top-3 right-2 px-2 bg-pink rounded-xl">-10%</div>
                { children }
            </div>
        </div>
    )
}

import {AppDispatch, AppThunk} from "../store";
import websocket from "../utils/socket";
import getFingetprint from "../utils/fingerprint";
import {v1 as uuid} from 'uuid';
import api from "../utils/api";
import {
    LOBBY_CHANGED,
    LOBBY_FIND_CHANGED,
    LOBBY_GAMEMODE_CHANGED, LOBBY_GET_INVITE_LINK, LOBBY_LOADING, LOBBY_MESSAGE, LOBBY_PINGS_UPDATE,
    LOBBY_PLAYER_ADDED, LOBBY_PLAYER_REMOVED, LOBBY_PRIVATE,
    LOBBY_REGION_CHANGED, LOBBY_REGION_GET, LOBBY_REMOVED, LOBBY_USE_INVITE_LINK,
    NEW_LOBBY,
    SOCKET_FAILURE
} from "../types/actions";
import {LobbyRegion} from "../types/store";
// @ts-ignore
import Ping from 'ping.js';

const Pinger = new Ping();

const getBaseMessage = (callback: string): { [k: string]: any } => {
    return {
        requestID: uuid(),
        callback: "callback_" + callback,
        token: localStorage.getItem("token")
    }
}

//--------------------------------------------
//                  PINGER
//--------------------------------------------

const updatePings = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const lobbyRegions = getState().lobby.regions;
    let pings = getState().lobby.pings;
    lobbyRegions.map(async (value, index) =>
        pings[value.Name] = await Pinger.ping(value.Address));
    dispatch({type: LOBBY_PINGS_UPDATE, pings: pings, loadings: {}})
}

//--------------------------------------------
//                  REST API
//--------------------------------------------

const createLobby = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    dispatch({type: LOBBY_LOADING, loadings: {create: true}});
    api.post('lobby/createLobby', {
        token: localStorage.getItem("token")
    }).then(response => {
        const {reason} = response.data;
        const id = reason.redirect.match(/\/lobby\/(.*)/)[1];
        dispatch({type: NEW_LOBBY, id: id, loadings: {create: false}})
    }).catch(error => {
        dispatch({type: NEW_LOBBY, loadings: {create: false}})
    });
}

//--------------------------------------------
//                  WebSockets
//--------------------------------------------

const removeLobby = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(removeLobby.name);
    request.lobbyOid = id;
    dispatch({type: LOBBY_LOADING, loadings: {remove: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.method !== 'callback_removeLobby') return;
        if (data.success)
            dispatch({type: LOBBY_REMOVED, loadings: {remove: false}});
        websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {remove: false}});
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request))
}

const getRegionsLobby = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(getRegionsLobby.name);
    request.lobbyOid = id;
    dispatch({type: LOBBY_LOADING, loadings: {getregion: true}});

    const listener = async (event: MessageEvent<any>) => {
        const data = JSON.parse(event.data);
        if (data.callback !== 'callback_getRegionsLobby') return;
        if (data.success) {
            let regions = [];
            let pings: { [key: string]: string } = {};
            for (let country in data.reason)
                for (let regionId in data.reason[country]) {
                    const region = data.reason[country][regionId] as LobbyRegion;
                    pings[region.Name] = await Pinger.ping(region.Address);
                    region.country = country;
                    regions.push(region);
                }
            dispatch({type: LOBBY_REGION_GET, regions: regions, pings: pings, loadings: {getregion: false}});
            websocket.removeEventListener("message", listener);
        }
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {getregion: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

const changeRegionLobby = (id: string, region: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(changeRegionLobby.name);
    request.lobbyOid = id;
    request.region = region;
    dispatch({type: LOBBY_LOADING, loadings: {region: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.callback !== 'callback_changeRegionLobby') return;
        if (data.success) {
            dispatch({type: LOBBY_REGION_CHANGED, region: region, loadings: {region: false}});
        }
        websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {region: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

const changeGameTypeLobby = (id: string, gamemode: 1 | 2 | 3 | 5): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(changeGameTypeLobby.name);
    request.lobbyOid = id;
    request.gamemode = gamemode;
    dispatch({type: LOBBY_LOADING, loadings: {gamemode: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.callback !== 'callback_changeGameTypeLobby') return;
        if (data.success) {
            dispatch({type: LOBBY_GAMEMODE_CHANGED, gamemode: gamemode, loadings: {gamemode: false}});

        }
        websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {gamemode: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

const addPlayerLobby = (id: string, playerId: number): AppThunk => async (dispatch: AppDispatch, getState) => {
    let request = getBaseMessage(addPlayerLobby.name);
    request.lobbyOid = id;
    request.playerToAddID = playerId;
    dispatch({type: LOBBY_LOADING, loadings: {addplayer: true}});
    websocket.onopen = () => {
        websocket.send(JSON.stringify(request))
        websocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.method !== 'callback_addPlayerLobby') return;
            if (data.success) {
                const players = getState().lobby.players
                dispatch({type: LOBBY_PLAYER_ADDED, players: [...players, playerId], loadings: {addplayer: false}});

            }
        }
    }
    websocket.onerror = (error) => dispatch({type: SOCKET_FAILURE, loadings: {addplayer: false}});
}

const removePlayerLobby = (id: string, playerId: number): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(removePlayerLobby.name);
    request.lobbyOid = id;
    request.playerToAddID = playerId;
    dispatch({type: LOBBY_LOADING, loadings: {removeplayer: true}});
    websocket.onopen = () => {
        websocket.send(JSON.stringify(request))
        websocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.method !== 'callback_removePlayerLobby') return;
            if (data.success) {
                dispatch({type: LOBBY_PLAYER_REMOVED, loadings: {removeplayer: false}});

            }
        }
    }
    websocket.onerror = (error) => dispatch({type: SOCKET_FAILURE, loadings: {removeplayer: false}});
}

const setFindLobby = (id: string, find: boolean): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(setFindLobby.name);
    request.lobbyOid = id;
    request.findstate = find;
    dispatch({type: LOBBY_LOADING, loadings: {find: true}});
    websocket.onopen = () => {
        websocket.send(JSON.stringify(request))
        websocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.callback !== 'callback_setFindLobby') return;
            if (data.success) {
                dispatch({type: LOBBY_FIND_CHANGED, finding: find, loadings: {find: false}});

            }
        };
    }
    websocket.onerror = (error) => dispatch({type: SOCKET_FAILURE, loadings: {find: false}});
}

const setPrivateLobby = (id: string, privateMode: boolean): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(setPrivateLobby.name);
    request.lobbyOid = id;
    request.private = privateMode;
    dispatch({type: LOBBY_LOADING, loadings: {private: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.callback !== 'callback_setPrivateLobby') return;
        if (data.success) {
            dispatch({type: LOBBY_PRIVATE, private: privateMode, loadings: {private: false}});

        }
        websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {private: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

const getLobby = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(getLobby.name);
    request.lobbyOid = id;
    dispatch({type: LOBBY_CHANGED, loadings: {getlobby: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.callback !== 'callback_getLobby')
            return;
        if (data.success) {
            dispatch({
                type: LOBBY_CHANGED,
                players: data.reason.players,
                chat: data.reason.chat,
                gamemode: data.reason.gamemode,
                finding: data.reason.finding,
                region: data.reason.region,
                private: data.reason.private,
                loadings: {
                    getlobby: false,
                    msglobby: false,
                    gamemode: false,
                    region: false,
                    private: false
                }
            });
        }
        //websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {getlobby: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

const useInviteLinkLobby = (id: string, invite: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(useInviteLinkLobby.name);
    request.lobbyOid = id;
    request.invitecode = invite;
    dispatch({type: LOBBY_LOADING, loadings: {uselink: true}});
    websocket.onopen = () => {
        websocket.send(JSON.stringify(request))
        websocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.method !== 'callback_useInviteLinkLobby') return;
            if (data.success) {
                dispatch({type: LOBBY_USE_INVITE_LINK, loadings: {uselink: false}});

            }
        };
    }
    websocket.onerror = (error) => dispatch({type: SOCKET_FAILURE, loadings: {uselink: false}});
}

const getInviteLinkLobby = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(getInviteLinkLobby.name);
    request.lobbyOid = id;
    dispatch({type: LOBBY_LOADING, loadings: {getlink: true}});
    websocket.onopen = () => {
        websocket.send(JSON.stringify(request))
        websocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.method !== 'callback_getInviteLinkLobby') return;
            if (data.success) {
                dispatch({type: LOBBY_GET_INVITE_LINK, loadings: {getlink: false}});

            }
        };
    }
    websocket.onerror = (error) => dispatch({type: SOCKET_FAILURE, loadings: {getlink: false}});
}

const msgChatLobby = (id: string, message: string): AppThunk => async (dispatch: AppDispatch) => {
    let request = getBaseMessage(msgChatLobby.name);
    request.lobbyOid = id;
    request.msg = message
    dispatch({type: LOBBY_LOADING, loadings: {msglobby: true}});

    const listener = (event: MessageEvent<any>) =>
    {
        const data = JSON.parse(event.data);
        if (data.method !== 'callback_msgChatLobby') return;
        if (data.success) {
            dispatch({type: LOBBY_MESSAGE, message: message, loadings: {msglobby: false}});

        }
        websocket.removeEventListener("message", listener);
    }

    const error_listener = (error: Event) => {
        dispatch({type: SOCKET_FAILURE, loadings: {msglobby: false}})
        websocket.removeEventListener("error", error_listener);
    }

    websocket.addEventListener("message", listener);
    websocket.addEventListener("error", error_listener);
    websocket.send(JSON.stringify(request));
}

export {
    createLobby,
    removeLobby,
    updatePings,
    getRegionsLobby,
    changeRegionLobby,
    changeGameTypeLobby,
    addPlayerLobby,
    removePlayerLobby,
    setFindLobby,
    setPrivateLobby,
    getLobby,
    useInviteLinkLobby,
    getInviteLinkLobby,
    msgChatLobby
}